import React, { useEffect, useRef, useState } from 'react';

import { pdfjs } from 'react-pdf';
import { Box } from '@mui/material';
import DocumentView from './DocumentView';
import DiffView from './DiffView';
import TOC from './TOC';
import { documentService } from '../../services/DocumentService';
import { DocumentDiffChange, DocumentDiffResult, DocumentNode } from '../../types/RealtaDocument';
import { inchToPx } from './utils';
import { HEIGHT } from './type';
import { useLocation } from '@gatsbyjs/reach-router';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const offset = 80;

const CompareDocument: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const originalDocUuid = queryParams.get('original');
  const updatedDocUuid = queryParams.get('updated');
  const pdfUrlOriginal = `https://ingestion-analysis-process-doc-bucket.s3.ap-southeast-2.amazonaws.com/${originalDocUuid}/source.pdf`;
  const pdfUrlUpdated = `https://ingestion-analysis-process-doc-bucket.s3.ap-southeast-2.amazonaws.com/${updatedDocUuid}/source.pdf`;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [diffChanges, setDiffChanges] = useState<DocumentDiffResult | null>(null);
  const originalDocRef = useRef<HTMLDivElement>(null);
  const updatedDocRef = useRef<HTMLDivElement>(null);
  const [focusDiffChange, setFocusDiffChange] = useState<DocumentDiffChange | null>(null);
  const [focusUpdatedDiffChange, setFocusUpdatedDiffChange] = useState<DocumentDiffChange | null>(null);

  useEffect(() => {
    const init = async () => {
      if (!originalDocUuid || !updatedDocUuid) {
        return;
      }
      setIsLoading(true);

      const diffResult = await documentService.compareDocuments(originalDocUuid, updatedDocUuid);
      setDiffChanges(diffResult);

      setIsLoading(false);
    };

    init();
  }, []);

  const handleDiffClick = (diff: DocumentDiffChange) => {
    if (originalDocRef.current) {
      const startBoundryPage = diff.startBoundryPage > 1 ? diff.startBoundryPage - 1 : 0;
      const top = HEIGHT * startBoundryPage + inchToPx(diff.startBoundryY);
      originalDocRef.current.scrollTo({ top: top - offset, behavior: 'smooth' });
    }
    if (updatedDocRef.current) {
      const updatedDiff = diff.update ? diff.update : diff;
      const startBoundryPage = updatedDiff.startBoundryPage > 1 ? updatedDiff.startBoundryPage - 1 : 0;
      const top = HEIGHT * startBoundryPage + inchToPx(updatedDiff.startBoundryY);
      updatedDocRef.current.scrollTo({ top: top - offset, behavior: 'instant' });
    }

    switch (diff.type) {
      case 'added':
        setFocusUpdatedDiffChange(diff);
        break;
      case 'deleted':
        setFocusDiffChange(diff);
        break;

      case 'updated':
        if (diffChanges?.doc1ChangeMap.has(diff.uuid)) {
          setFocusDiffChange(diff);
          diff.update && setFocusUpdatedDiffChange(diff.update);
        } else {
          setFocusUpdatedDiffChange(diff);
          diff.update && setFocusDiffChange(diff.update);
        }
        break;
    }
  };

  const handleNodeClick = (node: DocumentNode) => {
    if (originalDocRef.current && updatedDocRef.current && node.startBoundryPage && node.startBoundryY) {
      const startBoundryPage = node.startBoundryPage && node.startBoundryPage > 1 ? node.startBoundryPage - 1 : 0;
      const top = HEIGHT * startBoundryPage + inchToPx(node.startBoundryY);

      originalDocRef.current.scrollTo({ top: top - offset, behavior: 'smooth' });
      updatedDocRef.current.scrollTo({ top: top - offset, behavior: 'smooth' });
    }
  };

  const handleFocusDiffChange = (diff: DocumentDiffChange, isRoot?: boolean) => {
    if (isRoot) {
      setFocusDiffChange(diff);
      if (updatedDocRef.current) {
        const updatedDiff = diff.update ? diff.update : diff;
        setFocusUpdatedDiffChange(updatedDiff);
        const startBoundryPage = updatedDiff.startBoundryPage > 1 ? updatedDiff.startBoundryPage - 1 : 0;
        const top = HEIGHT * startBoundryPage + inchToPx(updatedDiff.startBoundryY);
        updatedDocRef.current.scrollTo({ top: top - offset, behavior: 'smooth' });
      }
    } else {
      setFocusUpdatedDiffChange(diff);
      if (originalDocRef.current) {
        const updatedDiff = diff.update ? diff.update : diff;
        setFocusDiffChange(updatedDiff);
        const startBoundryPage = updatedDiff.startBoundryPage > 1 ? updatedDiff.startBoundryPage - 1 : 0;
        const top = HEIGHT * startBoundryPage + inchToPx(updatedDiff.startBoundryY);
        originalDocRef.current.scrollTo({ top: top - offset, behavior: 'smooth' });
      }
    }
  };

  if (!originalDocUuid || !updatedDocUuid) {
    return <Box>You need to add 2 params: original and updated as 2 document ids in url to compare documents</Box>;
  }

  return (
    <Box>
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          bgcolor="black"
          color="white"
          zIndex={10}
        >
          <h2>Comparing documents...</h2>
        </Box>
      )}
      <TOC documentUuid={originalDocUuid} diffChanges={diffChanges?.tocChangeMap} onClick={handleNodeClick} />
      <Box display="flex" gap={1}>
        <Box sx={{ display: 'flex', gap: 2, flexShrink: 0 }}>
          <Box height="calc(100vh - 80px)" sx={{ overflowY: 'auto' }} ref={originalDocRef}>
            <DocumentView
              url={pdfUrlOriginal}
              diffs={diffChanges?.doc1ChangeMap}
              focusDiffChange={focusDiffChange}
              onDiffChangeClick={handleFocusDiffChange}
            />
          </Box>
          <Box height="calc(100vh - 80px)" sx={{ overflowY: 'auto' }} ref={updatedDocRef}>
            <DocumentView
              url={pdfUrlUpdated}
              diffs={diffChanges?.doc2ChangeMap}
              isRoot={false}
              focusDiffChange={focusUpdatedDiffChange}
              onDiffChangeClick={handleFocusDiffChange}
            />
          </Box>
        </Box>
        <Box height="calc(100vh - 80px)" sx={{ overflowY: 'auto' }}>
          <DiffView diffs={diffChanges?.mergedChangeMap} showDetails={false} onDiffClick={handleDiffClick} />
        </Box>
      </Box>
    </Box>
  );
};
export default CompareDocument;
