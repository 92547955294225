// @ts-nocheck
import React from 'react';
import { Router } from '@gatsbyjs/reach-router';
import AppLayout from '../components/layout/AppLayout';
import CompareDocument from '../components/CompareDocument/CompareDocument';

const Compare = () => {
  return (
    <AppLayout>
      <Router>
        <CompareDocument path="/document-comparison" />
      </Router>
    </AppLayout>
  );
};
export default Compare;
