import React, { forwardRef, useState } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
import { Box } from '@mui/material';
import { getDiffColor, inchToPx } from './utils';
import { DocumentDiffChange, DocumentDiffChangeMap, DocumentDiffChangeType } from '../../types/RealtaDocument';
import { HEIGHT, WIDTH } from './type';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

type DocumentViewProps = {
  url: string;
  diffs?: DocumentDiffChangeMap;
  isRoot?: boolean;
  focusDiffChange?: DocumentDiffChange | null;
  onDiffChangeClick?: (diff: DocumentDiffChange, isRoot?: boolean) => void;
};

const DocumentView = forwardRef<HTMLDivElement, DocumentViewProps>(
  ({ url, diffs, focusDiffChange, onDiffChangeClick, isRoot = true }, ref) => {
    const [numPages, setNumPages] = useState<number>();

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
      setNumPages(numPages);
    }

    const calculateHeight = (diff: DocumentDiffChange) => {
      const startBoundryPage = diff.startBoundryPage > 1 ? diff.startBoundryPage - 1 : 0;
      const top = HEIGHT * startBoundryPage + inchToPx(diff.startBoundryY);

      const offset =
        diff.endBoundryPage - diff.startBoundryPage === 0 ? 0 : HEIGHT * (diff.endBoundryPage - diff.startBoundryPage);
      const height = offset + HEIGHT * startBoundryPage + inchToPx(diff.endBoundryY) - top;
      return { top, height };
    };

    const handleShowChange = (e: React.MouseEvent<HTMLDivElement>, diff: DocumentDiffChange) => {
      onDiffChangeClick && onDiffChangeClick(diff, isRoot);
      if (diff.type === DocumentDiffChangeType.UPDATED) {
        const popup = document.createElement('div');
        popup.innerText = `${isRoot ? 'Update to' : 'Update from'}:\n${diff.update?.content}`;
        popup.style.position = 'absolute';
        popup.style.top = `${e.clientY}px`;
        popup.style.left = `${e.clientX + 10}px`;
        popup.style.backgroundColor = 'white';
        popup.style.padding = '5px';
        popup.style.border = '1px solid black';
        popup.style.zIndex = '1001';
        document.body.appendChild(popup);

        setTimeout(() => {
          document.body.removeChild(popup);
        }, 3000);
      }
    };

    return (
      <Box position="relative" height="fit-content" sx={{ scrollBehavior: 'smooth' }} ref={ref}>
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          {numPages &&
            Array.from({ length: numPages }, (_, index) => index + 1).map((pageNumber) => (
              /* This box to keep pdf pages are always in the same size */
              <Box width={WIDTH} height={HEIGHT} overflow="hidden">
                <Page pageNumber={pageNumber} width={WIDTH} />
              </Box>
            ))}
        </Document>
        {diffs?.values() &&
          Array.from(diffs.values()).map((diff, index) => (
            <Box
              key={index}
              position="absolute"
              top={calculateHeight(diff).top}
              height={calculateHeight(diff).height}
              left={0}
              width="100%"
              bgcolor={getDiffColor(diff.type)}
              zIndex={2}
              onClick={(e) => handleShowChange(e, diff)}
              sx={{
                cursor: 'pointer',
                border: diff.uuid === focusDiffChange?.uuid ? '1px solid black' : undefined,
                // pointerEvents: 'none',
              }}
            />
          ))}
      </Box>
    );
  }
);
export default DocumentView;
